
import { IWSMessage } from "./wsMessage";

export type PayloadType = "file" | "multiple files" | "folder";

export const WSMessageTypePayloadType = "payload-type";

export interface IWSMessageDataPayloadType extends IWSMessage {

    type: PayloadType;
}

export class WSMessageDataPayloadType implements IWSMessageDataPayloadType {

    constructor(
        public type: PayloadType
    ) { }

    public messageType = WSMessageTypePayloadType;
}

