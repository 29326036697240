
import { IWSMessage } from "./wsMessage";

export const WSMessageTypePayloadDescription = "payload-description";

export interface IPayloadItemDescription {
    id: number;
    name: string;
    type: "file" | "folder";
    size: number;
    path: string;
}


export interface IWSMessageDataPayloadDescription extends IWSMessage {

    items: IPayloadItemDescription[];
}

export class WSMessageDataPayloadDescription implements IWSMessageDataPayloadDescription {

    constructor(
        public items: IPayloadItemDescription[]
    ) { }

    public messageType = WSMessageTypePayloadDescription;
}

