
import { IWSMessage } from "./wsMessage";

export const WSMessageTypeRegisterAsReceiver = "register-as-receiver";

export interface IWSMessageDataRegisterAsReceiver extends IWSMessage {
    shareID: string;
}

export class WSMessageDataRegisterAsReceiver implements IWSMessageDataRegisterAsReceiver {

    constructor(public shareID: string) { };

    public messageType = WSMessageTypeRegisterAsReceiver;
}

