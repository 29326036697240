
export class Progress {

    public static calcProgress = (maxSize: number, currentSize: number): number => {
        if (maxSize === 0) {
            return 0;
        }

        // Calculate the progress
        const result = Math.round((currentSize / maxSize) * 100);

        // Check if for some reason the current size is bigger than the max size
        if (result > 100) {
            return 100;
        }

        return result;
    }
}