import { WSMessageDataBatchEnd, WSMessageDataBatchStart } from "../socketIOMessages/dataSharing/batchMessage";
import { IWSMessage } from "../socketIOMessages/dataSharing/wsMessage";

export class BatchWork {

  public static NumberOfMessagesPerBatch = 500;
  public static NumberParalellBatches = 100;

  constructor(
    batchName: string,
    batchType: "description" | "data",
    data: any,
    performSendBatch: (data: any, batchPart: number) => Promise<boolean>,
    onBatchCompleted: () => void,
    sendMessage: (message: IWSMessage) => void,
    somethingToDo: (data: any) => boolean,
  ) {
    this.batchName = batchName;
    this.batchType = batchType;
    this.data = data;
    this.performSendBatch = performSendBatch;
    this.onBatchCompleted = onBatchCompleted;
    this.sendMessage = sendMessage;
    this.somethingToDo = somethingToDo;
  }

  public start = () => {

    const performSend = async () => {
      this.currentBatchNumber++;
      let currentBatchPart = this.currentBatchNumber;
      this.listBatchesWaitingConfirmation.push(currentBatchPart);

      this.sendMessage(new WSMessageDataBatchStart(this.batchName, currentBatchPart, this.batchType));

      //console.log("Sending batch", this.batchName, "part", currentBatchPart);
      const finished = await this.performSendBatch(this.data, currentBatchPart);

      this.sendMessage(new WSMessageDataBatchEnd(this.batchName, currentBatchPart, this.batchType));

      if (finished) {
        //console.log(`Marking batch ${this.batchName} as done at the start (${currentBatchPart})...`);
        this.finished = true;
        return true;
      }

      return false;
    };

    const attemptSend = (batchesCount: number) => {

      if (batchesCount <= 0) return;

      performSend().then((finished) => {
        if (!finished) {
          attemptSend(batchesCount - 1);
        }
      });
    };

    this.currentBatchNumber = -1;
    attemptSend(BatchWork.NumberParalellBatches);

    // console.log("Start is almost finished!!", this.batchName);

    //performSend().then((finished) => {
    //  if (!finished) {
    //    performSend().then((finished) => {
    //      if (!finished) {
    //        performSend().then((finished) => {
    //          if (!finished) {
    //            performSend().then(() => {
    //              return;
    //            });
    //          }
    //        });
    //      }
    //    });
    //  }
    //});


    //for (let i = 0; i < 10; i++) {
    //  let finished = await aa();
    //  if (finished) {
    //    return;
    //  }
    //}


    // We need to send 2 batches

    // Send first batch
    //this.currentBatchNumber = 0;
    //
    //let currentBatchPart = this.currentBatchNumber;
    //this.listBatchesWaitingConfirmation.push(currentBatchPart);
    //
    //this.sendMessage(new WSMessageDataBatchStart(this.batchName, currentBatchPart, this.batchType));
    //this.performSendBatch(this.data, currentBatchPart).then((finished) => {
    //
    //  this.sendMessage(new WSMessageDataBatchEnd(this.batchName, currentBatchPart, this.batchType));
    //
    //  if (finished) {
    //    console.log(`Marking batch ${this.batchName} as done at the start (1)...`);
    //    this.finished = true;
    //    return;
    //  }
    //
    //  // Send second batch
    //  this.currentBatchNumber += 1;
    //  currentBatchPart = this.currentBatchNumber;
    //
    //  this.listBatchesWaitingConfirmation.push(currentBatchPart);
    //
    //  this.sendMessage(new WSMessageDataBatchStart(this.batchName, currentBatchPart, this.batchType));
    //  this.performSendBatch(this.data, currentBatchPart).then((finished) => {
    //    this.sendMessage(new WSMessageDataBatchEnd(this.batchName, currentBatchPart, this.batchType));
    //
    //    if (finished) {
    //      console.log(`Marking batch ${this.batchName} as done at the start (2)...`);
    //      this.finished = true;
    //      return;
    //    }
    //  });
    //});

  }

  public confirm = (batchPart: number) => {

    // Remove the batch part from the list of batches waiting for confirmation
    this.listBatchesWaitingConfirmation = this.listBatchesWaitingConfirmation.filter(x => x !== batchPart);

    if (this.finished === false) {
      this.proceed();
    }

    if (this.finished && this.listBatchesWaitingConfirmation.length === 0) {
      this.onBatchCompleted();
    }

    //console.log("After confirmation, on the batch", this.batchName, "we have", this.listBatchesWaitingConfirmation.length, "batches waiting for confirmation");
  }

  public proceed = () => {


    if (this.somethingToDo(this.data) === false) {
      return;
    }
    //console.log("After checking proceeding, on the batch", this.batchName, "we have", this.listBatchesWaitingConfirmation.length, "batches waiting for confirmation");

    this.currentBatchNumber += 1;
    this.listBatchesWaitingConfirmation.push(this.currentBatchNumber);

    const currentBatchPart = this.currentBatchNumber;

    this.sendMessage(new WSMessageDataBatchStart(this.batchName, currentBatchPart, this.batchType));

    this.performSendBatch(this.data, currentBatchPart).then((finished) => {
      this.sendMessage(new WSMessageDataBatchEnd(this.batchName, currentBatchPart, this.batchType));

      if (finished) {
        this.finished = true;
        return;
      }
    });
  }

  public isFinished = () => {
    return this.finished;
  }

  public getBatchesWaitingConfirmationCount = () => {
    return this.listBatchesWaitingConfirmation.length;
  }

  public batchName: string;
  public batchType: "description" | "data";
  private data: any;
  private performSendBatch: (data: any, batchPart: number) => Promise<boolean>;
  private onBatchCompleted: () => void;
  private sendMessage: (message: IWSMessage) => void;
  private somethingToDo: (data: any) => boolean;
  private finished = false;

  private listBatchesWaitingConfirmation: number[] = [];

  private currentBatchNumber = -1;
}
