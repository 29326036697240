
export function FileDownloadTrigger(fileContents: Blob, fileName: string) {
    const url = window.URL.createObjectURL(fileContents);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 100);
}