
// This message is sent by the server to the client, with the 
// generated code assing to that client

import { IWSMessage } from "../dataSharing/wsMessage";

export const WSMessageTypeSendClientCode = "send-client-code";

export interface IWSMessageDataSendClientCode extends IWSMessage {
  clientCode: string;
}

export class WSMessageDataSendClientCode implements IWSMessageDataSendClientCode {

  constructor(
    public clientCode: string
  ) { }

  public messageType = WSMessageTypeSendClientCode;
}