import { PayloadType } from "../../common/socketIOMessages/dataSharing/payloadTypeMessage";
import { FileInfoLocal } from "../../common/data/fileInfoLocal";
import { FileInformation } from "../common/fileInformation";
import { ItemReceiver } from "../common/itemReceiver";
import { getCardStyle } from "./cardStyles";

interface CardItemsToReceiveDescriptionProps {
    payloadType: PayloadType | undefined;
    availableItems: FileInfoLocal[];
    handleDoReceiveAction: (action: "downloadFile" | "downloadFolder" | "syncFolder") => void;
    cardIndex: number
}

export function CardItemsToReceiveDescription({
    payloadType,
    availableItems,
    handleDoReceiveAction,
    cardIndex
}: CardItemsToReceiveDescriptionProps): JSX.Element {

    return (
        <div
            className={`card card-items-to-receive ${getCardStyle(cardIndex)}`}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    className="card-text-h1 mb-4"
                >
                    What you're about to receive
                </div>

                <FileInformation items={availableItems}></FileInformation>

                <div className="mt-4">
                    {payloadType && (
                        <ItemReceiver
                            payloadType={payloadType}
                            callbackDoAction={handleDoReceiveAction} />
                    )}

                </div>
            </div>
        </div>
    );
}