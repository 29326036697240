import { getCardStyle } from "./cardStyles";

interface CardDiscoveringItemsProps {
    cardIndex: number
}

export function CardDiscoveringItems({
    cardIndex
}: CardDiscoveringItemsProps): JSX.Element {

    return (
        <div
            className={`card card-discovering-items ${getCardStyle(cardIndex)}`}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className="card-text-h1">
                    Receiving items information...
                </div>
                <div className="card-text-h1">
                    Please Wait
                </div>
            </div>
        </div>
    );
}