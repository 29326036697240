import { IWSMessage } from "./wsMessage";

export const WSMessageTypeStartTransfer = "start-transfer";

export interface IWSMessageDataStartTransfer extends IWSMessage {

}

export class WSMessageDataStartTransfer implements IWSMessageDataStartTransfer {

    public messageType = WSMessageTypeStartTransfer;
}

