
export class FileInfoLocal {

    constructor(
        public id: number,
        public name: string,
        public type: "file" | "folder",
        public path: string,
        public size: number,
        public fileHandle: File | undefined,
    ) {

    }
}