import { Socket } from "socket.io-client";

import {
  ConnectionPurpose
} from "../../common/socketIOMessages/clientConnection/sendPurpose";

import {
  WSMessageDataRegisterAsReceiver
} from "../../common/socketIOMessages/dataSharing/registerAsReceiver";

import {
  IWSMessageDataBatchEnd,
  WSMessageDataBatchReceived,
  WSMessageTypeBatchEnd
} from "../../common/socketIOMessages/dataSharing/batchMessage";

import {
  IWSMessageDataPayloadType,
  PayloadType,
  WSMessageTypePayloadType
} from "../../common/socketIOMessages/dataSharing/payloadTypeMessage";

import {
  IPayloadItemDescription,
  IWSMessageDataPayloadDescription,
  WSMessageTypePayloadDescription
} from "../../common/socketIOMessages/dataSharing/payloadDescriptionMessage";

import {
  IWSMessageDataFileTransferHead,
  WSMessageTypeFileTransferHead
} from "../../common/socketIOMessages/dataSharing/fileTransferHead";

import {
  IWSMessageDataFileTransferPart,
  WSMessageTypeFileTransferPart
} from "../../common/socketIOMessages/dataSharing/fileTransferPart";

import {
  IWSMessageDataFileTransferTail,
  WSMessageTypeFileTransferTail
} from "../../common/socketIOMessages/dataSharing/fileTransferTail";

import {
  IWSMessageDataPayloadDescriptionEnd,
  WSMessageTypePayloadDescriptionEnd
} from "../../common/socketIOMessages/dataSharing/payloadDescriptionEndMessage";

import {
  WSMessageTypeShareNotAvailable
} from "../../common/socketIOMessages/dataSharing/shareNotAvailableMessage";

import { FileInfoLocal } from "../../common/data/fileInfoLocal";

import { WSConnection } from "./wsConnection";
import { WSMessageDataStartTransfer } from "../../common/socketIOMessages/dataSharing/startTransferMessage";
import { createDirectories } from "../../common/files/localFileCreation";
import { IWSMessage } from "../../common/socketIOMessages/dataSharing/wsMessage";
import { TextManip } from "../../common/textManip/textManip";
import { BatchWork } from "../../common/batches/batch";
import { Progress } from "../progress";
import { WSMessageTypePeerDisconected } from "../../common/socketIOMessages/dataSharing/peerDisconnected";

export class WSConnectionReceiver extends WSConnection {

  constructor() {
    super(ConnectionPurpose.Receiver);
  }

  public registerAsReceiver(
    shareID: string,
    callbackPayloadDiscoveryStart: () => void,
    callbackPayloadDescription: (payloadType: PayloadType, items: FileInfoLocal[]) => void,
    callbackReportItemsProgress: (itemsProgress: number, itemProgress: number, itemName: string) => void,
    callbackReportTransferComplete: () => void,
    callbackShareNotAvailableError: () => void,
    callbackPeerDisconnected: () => void,
  ): void {

    this.callbackPayloadDiscoveryStart = callbackPayloadDiscoveryStart;
    this.callbackPayloadDescription = callbackPayloadDescription;
    this.callBackReportItemsProgress = callbackReportItemsProgress;
    this.callbackReportTransferComplete = callbackReportTransferComplete;
    this.callbackShareNotAvailableError = callbackShareNotAvailableError;
    this.callbackPeerDisconnected = callbackPeerDisconnected;

    this.sendMessage(new WSMessageDataRegisterAsReceiver(shareID));
  }

  public downloadFolder = (destinationFolderHandle: FileSystemDirectoryHandle) => {

    // Save the folder handle
    this.folderDestinationHandle = destinationFolderHandle;

    // Send the list of files to the sender
    this.askServerForFiles();
  }

  public downloadFile = (
    fileID: number,
    callbackFileDownloaded: (fileContents: Blob, fileName: string) => void) => {

    this.callbackFileDownloaded = callbackFileDownloaded;

    // Find the info about the item being transmitted
    const itemInformation = this.itemsDescriptions.find((item) => {
      return item.id === fileID;
    });

    if (itemInformation === undefined) {
      console.error("ERROR: itemDescription is undefined");
      return;
    }

    // Send the list of files to the sender
    this.askServerForFiles();
  }

  public streamFile = (fileID: number, destinationFileHandle: FileSystemWritableFileStream) => {

    // Find the info about the item being transmitted
    const itemInformation = this.itemsDescriptions.find((item) => {
      return item.id === fileID;
    });

    if (itemInformation === undefined) {
      console.error("ERROR: itemDescription is undefined");
      return;
    }

    this.currentItemBeingReceived = itemInformation;
    this.currentFileDestinationHandle = destinationFileHandle;

    // Send the list of files to the sender
    this.askServerForFiles();
  }

  public ensureWritePermissions = async (directoryHandle: FileSystemDirectoryHandle) => {

    // Create a file handle for the file
    await directoryHandle.getFileHandle("___testFile___", { create: true });
    directoryHandle.removeEntry("___testFile___");
  }

  protected registerSocketEvents = (socket: Socket) => {

    socket.on(WSMessageTypeBatchEnd, async (data: IWSMessageDataBatchEnd) => {

      if (data.batchType === "description") {
        // Indicate that we just received the batch
        const msgData = new WSMessageDataBatchReceived(data.batchName, data.batchPart);
        this.sendMessage(msgData);

        return;
      }

      if (data.batchType === "data") {

        this.pendingBatchesToReport.push({ batchName: data.batchName, batchPart: data.batchPart });
        await this.processPendingMessages();
      }
    });

    socket.on(WSMessageTypeShareNotAvailable, (data: any) => {
      if (this.callbackShareNotAvailableError) {
        this.callbackShareNotAvailableError();
      }
    });

    socket.on(WSMessageTypePeerDisconected, (data: any) => {
      if (this.callbackPeerDisconnected) {
        this.callbackPeerDisconnected();
      }
    });

    socket.on(WSMessageTypePayloadType, (data: IWSMessageDataPayloadType) => {
      this.payloadType = data.type;
    });

    socket.on(WSMessageTypePayloadDescription, (data: IWSMessageDataPayloadDescription) => {
      if (this.itemsDescriptions.length === 0) {
        if (this.callbackPayloadDiscoveryStart !== undefined) {
          this.callbackPayloadDiscoveryStart();
        }
      }

      this.itemsDescriptions = [...this.itemsDescriptions, ...data.items];
    });

    socket.on(WSMessageTypePayloadDescriptionEnd, (data: IWSMessageDataPayloadDescriptionEnd) => {

      const arrItems = this.itemsDescriptions.map((item) => {
        return new FileInfoLocal(item.id, item.name, item.type, item.path, item.size, undefined);
      });

      if (this.callbackPayloadDescription) {
        this.callbackPayloadDescription(this.payloadType, arrItems);

        this.itemsToTransmitCount = arrItems.length;
      }
    });

    socket.on(WSMessageTypeFileTransferHead, async (data: IWSMessageDataFileTransferHead) => {
      this.testListMessages.push(data);
    });
    socket.on(WSMessageTypeFileTransferPart, async (data: IWSMessageDataFileTransferPart) => {
      this.testListMessages.push(data);
    });
    socket.on(WSMessageTypeFileTransferTail, async (data: IWSMessageDataFileTransferTail) => {
      this.testListMessages.push(data);
    });
  }

  protected processPendingMessages = async () => {

    if (this.processingMessages) {
      return;
    }

    this.processingMessages = true;

    while (this.testListMessages.length > 0) {
      const message = this.testListMessages.shift();

      if (message) {
        switch (message.messageType) {
          case WSMessageTypeFileTransferHead:
            {
              const data = message as IWSMessageDataFileTransferHead;
              // Find the info about the item being transmitted
              const itemInformation = this.itemsDescriptions.find((item) => {
                return item.id === data.fileID;
              });

              if (itemInformation === undefined) {
                console.error("ERROR: itemDescription is undefined");
                return;
              }

              this.currentItemBeingReceived = itemInformation;

              if (this.currentItemBeingReceived.type === "folder") {
                await this.prepareReceptionOfFolder(this.currentItemBeingReceived);
              }
              if (this.currentItemBeingReceived.type === "file") {
                await this.prepareReceptionOfFile(this.currentItemBeingReceived);
              }

              if (this.callBackReportItemsProgress !== undefined) {
                this.callBackReportItemsProgress(
                  Progress.calcProgress(this.itemsToTransmitCount, this.numberOfItemsHandled),
                  0,
                  this.currentItemBeingReceived.name
                );
              }
            }
            break;

          case WSMessageTypeFileTransferPart:
            {
              const data = message as IWSMessageDataFileTransferPart;

              // Check if we have a file handle or not
              if (this.currentFileDestinationHandle === undefined) {

                // Add the chunk to the buffer
                this.currentItemChunks.push(TextManip.base64StringToBinaryData(data.data));
              }
              else {

                // Append the chunk to the file
                await this.saveChunkToFile(this.currentFileDestinationHandle, data.data);
              }

              if (this.callBackReportItemsProgress !== undefined && this.currentItemBeingReceived !== undefined) {
                this.callBackReportItemsProgress(
                  Progress.calcProgress(this.itemsToTransmitCount, this.numberOfItemsHandled),
                  Progress.calcProgress(data.chunkTotal, data.chunkIndex),
                  this.currentItemBeingReceived.name
                );
              }
            }
            break;

          case WSMessageTypeFileTransferTail:

            // If there is a file handle, we need to close it
            if (this.currentFileDestinationHandle !== undefined) {
              await this.currentFileDestinationHandle.close();
              this.currentFileDestinationHandle = undefined;
            }
            else {

              // We're downloading the file into memory
              // We need to call the callback with the file contents

              const blob = new Blob(this.currentItemChunks);

              if (this.callbackFileDownloaded && this.currentItemBeingReceived) {
                this.callbackFileDownloaded(
                  blob,
                  this.currentItemBeingReceived.name
                );
              }
            }

            this.numberOfItemsHandled++;

            // Report completion if that's the case
            if (this.callbackReportTransferComplete !== undefined) {
              if (this.numberOfItemsHandled === this.itemsToTransmitCount) {
                this.callbackReportTransferComplete();
              }
            }
            break;

          default:
            {
              console.log("Unknown type of message", message.messageType);
            }
        }
      }

      if (this.testListMessages.length < ((BatchWork.NumberOfMessagesPerBatch * BatchWork.NumberParalellBatches) * 0.7)) {
        // If there's batches to report, report them
        while (this.pendingBatchesToReport.length > 0) {
          const batch = this.pendingBatchesToReport.shift();
          if (batch) {
            const msgData = new WSMessageDataBatchReceived(batch.batchName, batch.batchPart);
            this.sendMessage(msgData);
          }
        }
      }
    }

    // If there's batches to report, report them
    while (this.pendingBatchesToReport.length > 0) {
      const batch = this.pendingBatchesToReport.shift();
      if (batch) {
        const msgData = new WSMessageDataBatchReceived(batch.batchName, batch.batchPart);
        this.sendMessage(msgData);
      }
    }

    this.processingMessages = false;
  }

  protected saveChunkToFile = async (
    fileStreamWriteHandle: FileSystemWritableFileStream,
    fileChunk: string
  ) => {
    await fileStreamWriteHandle.write(TextManip.base64StringToBinaryData(fileChunk));
  }

  protected prepareReceptionOfFolder = async (item: IPayloadItemDescription) => {

    if (this.folderDestinationHandle === undefined) {
      return;
    }

    // Create a directory for the folder
    const folderPath = item.path + item.name + "/";

    await createDirectories(this.folderDestinationHandle, folderPath);
  }

  protected prepareReceptionOfFile = async (item: IPayloadItemDescription) => {

    // If we're streaming the file, we just need to clear the buffer of the file pieces
    // It should be empty, because, if we're streaming a file, this is the only file being transmitted

    if (this.folderDestinationHandle === undefined) {
      return;
    }

    if (this.fileShouldBeStreamed(this.payloadType, item)) {

      // Clear the buffer
      this.currentItemChunks = [];

      // Make sur the file handle is empty
      this.currentFileDestinationHandle = undefined;
    }
    else {

      // Create a file handle for the file
      const itemParentHandle = await createDirectories(this.folderDestinationHandle, item.path);
      const fileHandle = await itemParentHandle.getFileHandle(item.name, { create: true });

      this.currentFileDestinationHandle = await fileHandle.createWritable();
    }
  }

  protected fileShouldBeStreamed = (payloadType: PayloadType, item: IPayloadItemDescription) => {

    if (payloadType !== "file") {
      return false;
    }

    return item.size > this.getFileSizeStreamSizeThreshold();
  }

  public getFileSizeStreamSizeThreshold = () => {
    return 10_000_000;
  }

  protected askServerForFiles = () => {

    let startTransferDataMessage = new WSMessageDataStartTransfer();
    this.sendMessage(startTransferDataMessage);
  }


  private callbackShareNotAvailableError: (() => void) | undefined = undefined;
  private callbackPeerDisconnected: (() => void) | undefined = undefined;
  private callbackPayloadDiscoveryStart: (() => void) | undefined = undefined;
  private callbackPayloadDescription: ((payloadType: PayloadType, items: FileInfoLocal[]) => void) | undefined = undefined;
  private callBackReportItemsProgress: ((itemsProgress: number, itemProgress: number, itemName: string) => void) | undefined = undefined;
  private callbackReportTransferComplete: (() => void) | undefined = undefined;

  private callbackFileDownloaded: ((fileContents: Blob, fileName: string) => void) | undefined = undefined;

  protected payloadType: PayloadType = "folder";
  protected itemsDescriptions: IPayloadItemDescription[] = [];

  protected folderDestinationHandle: FileSystemDirectoryHandle | undefined = undefined;
  protected currentFileDestinationHandle: FileSystemWritableFileStream | undefined = undefined;

  protected itemsToTransmitCount: number = 0;
  protected numberOfItemsHandled = 0;

  protected currentItemBeingReceived: IPayloadItemDescription | undefined = undefined;

  // This is used for the cases when the file is being streamed
  protected currentItemChunks = new Array<ArrayBuffer>();

  // Temporary
  protected pendingBatchesToReport: { batchName: string, batchPart: number }[] = [];
  protected testListMessages: IWSMessage[] = [];
  protected processingMessages = false;
}