import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { FolderParser } from "../../data/folderParser";
import { FileInfoLocal } from "../../common/data/fileInfoLocal";
import { PayloadType } from "../../common/socketIOMessages/dataSharing/payloadTypeMessage";


interface IItemPickerProps {
    setPayloadType: (payloadType: PayloadType) => void;
    setSelectedItems: (items: FileInfoLocal[]) => void;
    setReadingFiles: (reading: boolean) => void;
}

export function ItemPicker({
    setPayloadType,
    setSelectedItems,
    setReadingFiles
}: IItemPickerProps) {

    // Input for file selection
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    // Input for folder selection
    const folderInputRef = useRef<HTMLInputElement | null>(null);

    // Flag to indicate if folder selection is available (probably not available in mobile browsers)
    const [folderSelectionIsAvailable, setFolderSelectionIsAvailable] = useState<boolean | undefined>();

    useEffect(() => {
        // Set the flag that indicates if folder selection is available
        setFolderSelectionIsAvailable(window.showDirectoryPicker !== undefined);
    }, []);

    // Handle for button "Send a file"
    const handleSelectFileToShare = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    // Handle for post file selection
    const handleFileSelected = (event: ChangeEvent<HTMLInputElement>) => {

        // Grab the files
        const files = event.target.files;

        if (files === null || files.length === 0) {
            return;
        }

        // Set the payload type
        if (files.length === 1) {
            setPayloadType("file");
        }
        else {
            setPayloadType("multiple files");
        }

        // Go through the files and create FileInfoLocal objects for them

        if (files && files.length > 0) {

            const selectedFiles = Array.from(files);
            const fileInfosLocal = selectedFiles.map((file, index) => {
                return new FileInfoLocal(
                    index + 1,
                    file.name,
                    "file",
                    file.webkitRelativePath,
                    file.size,
                    file
                );
            });
            setSelectedItems(fileInfosLocal);
        }
    };

    // Handle for button "Send a folder"
    const handleSelectFolderToShare = async () => {

        try {
            // Show the directory picker
            const directoryHandle = await window.showDirectoryPicker();

            // Reading the directlry might take a while. Show a message to the user
            setReadingFiles(true);

            // Get the contents of the folder
            const folderContents = await FolderParser.GetFolderContents(directoryHandle);

            // Set the payload type
            setPayloadType("folder");

            // Saving the items retrieved
            setSelectedItems(folderContents);

            // Clear the status message
            setReadingFiles(false);
        }
        catch (error) {

        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <button
                className="button-main"
                onClick={() => { handleSelectFileToShare() }}
            >
                Send files
            </button>

            {folderSelectionIsAvailable &&
                <>
                    <div >
                        <span>or</span>
                    </div>

                    <button
                        className="button-main"
                        onClick={() => { handleSelectFolderToShare() }}
                    >
                        Send a folder
                    </button>
                </>
            }

            {/* Input for file selection */}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelected}
                multiple
            />

            {/* Input for folder selection */}
            <input
                type="file"
                ref={folderInputRef}
                style={{ display: 'none' }}
            />
        </div>
    );
}