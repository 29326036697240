import React, { useEffect, useState } from 'react';
import { FileInfoLocal } from '../../common/data/fileInfoLocal';
import { humanFileSize } from '../../library/humanFileSize';

interface IFileInformationProps {
    items: FileInfoLocal[];
}

export function FileInformation({
    items
}: IFileInformationProps) {

    const [itemName, setItemName] = useState<string>("");
    const [itemSize, setItemSize] = useState<string>("");

    useEffect(() => {
        if (items.length === 1 && items[0].type === "file") {

            const file = items[0];

            setItemName(file.name);
            setItemSize(humanFileSize(file.size, 0));

        }
        else {

            // Calculate the number of files and the total size
            let totalSize = 0;
            let totalFiles = 0;
            for (const item of items) {
                totalSize += item.size;
                totalFiles++;
            }

            setItemName(`${totalFiles} files`);
            setItemSize(humanFileSize(totalSize, 0));
        }
    }, [items]);

    return (

        <>
            {
                items.length > 0 &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.3em'
                    }}
                >
                    <div
                        className='card-text-h3'
                        style={{
                            display: '-webkit-box',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 1
                        }}>
                        {itemName}
                    </div>
                    <div
                        className='card-text-h3'
                    >
                        {itemSize}
                    </div>
                </div >
            }
        </>
    )
}