export class TextManip {

    /**
    * Converts binary data (ArrayBuffer) to a base64 encoded string.
    * @param {ArrayBuffer} arrayBuffer - The binary data.
    * @returns {string} The base64 encoded string.
    */
    public static binaryDataToBase64String(arrayBuffer: ArrayBuffer): string {
        const byteArray = new Uint8Array(arrayBuffer);
        const byteList = Array.from(byteArray);
        return btoa(byteList.map(byte => String.fromCharCode(byte)).join(''));
    }

    /**
    * Converts a base64 encoded string to binary data (ArrayBuffer).
    * @param {string} base64String - The base64 encoded string.
    * @returns {ArrayBuffer} The binary data.
    */
    public static base64StringToBinaryData(base64String: string): ArrayBuffer {
        const binaryString = atob(base64String);
        const byteLength = binaryString.length;
        const byteArray = new Uint8Array(byteLength);

        for (let i = 0; i < byteLength; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
        }

        return byteArray.buffer;
    }
}