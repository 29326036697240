import React from "react";
import { getCardStyle } from "./cardStyles";

interface CardBuyCoffeeProps {
    cardIndex: number;
}

export function CardBuyCoffee({
    cardIndex
}: CardBuyCoffeeProps): JSX.Element {

    const openNewPage = () => {
        window.open('https://www.buymeacoffee.com/nuno20090', '_blank');
    };

    return (



        <div
            className={`card card-buy-coffee ${getCardStyle(cardIndex)}`}>

            <div
                className="card-text-h1"
            >
                Buy me a coffee or a broccoli?
            </div>

            <div>
                <button
                    className="button-main mt-8"
                    onClick={openNewPage}
                >
                    Sure! ☕🥦
                </button>
            </div>


        </div>
    )
}