
import { IWSMessage } from "./wsMessage";


export const WSMessageTypePeerDisconected = "peer-disconected";

export interface IWSMessageDataPeerDisconected extends IWSMessage {
}

export class WSMessageDataPeerDisconected implements IWSMessageDataPeerDisconected {
    public messageType = WSMessageTypePeerDisconected;
}

