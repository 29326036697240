
import { IWSMessage } from "./wsMessage";


export const WSMessageTypeStartTransferEnd = "start-transfer-end";

export interface IWSMessageDataStartTransferEnd extends IWSMessage {

}

export class WSMessageDataStartTransferEnd implements IWSMessageDataStartTransferEnd {

    public messageType = WSMessageTypeStartTransferEnd;
}

