import React from 'react';

import { FileSharing } from './components/fileSharing';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';

function App() {
  return (
    <Router>

      <FileSharing />

    </Router>
  );
}

export default App;
