import { FileInfoLocal } from "../common/data/fileInfoLocal";

export class FolderParser {

    public static async GetFolderContents(directoryHandle: FileSystemDirectoryHandle): Promise<FileInfoLocal[]> {

        let itemID = 0;
        const localItems: FileInfoLocal[] = [];

        const onFileFound = (relativePath: string, fileHandle: File) => {
            itemID++;

            // Create new FileInfoLocal to represent the file
            const newFileInfoLocal = new FileInfoLocal(
                itemID,
                fileHandle.name,
                "file",
                relativePath,
                fileHandle.size,
                fileHandle,
            );

            localItems.push(newFileInfoLocal);
        }

        const onFolderFound = (relativePath: string, name: string) => {
            itemID++;

            // Create new FileInfoLocal to represent the folder
            const newFileInfoLocal = new FileInfoLocal(
                itemID,
                name,
                "folder",
                relativePath,
                0,
                undefined,
            );

            localItems.push(newFileInfoLocal);
        }

        await FolderParser.listFiles(directoryHandle, "", onFileFound, onFolderFound);

        return localItems;
    }


    private static async listFiles(
        directoryHandle: FileSystemDirectoryHandle,
        relativePath: string,
        onFileFound: (relativePath: string, fileHandle: File) => void,
        onFolderFound: (relativePath: string, name: string) => void,
    ): Promise<void> {
        for await (const entry of directoryHandle.values()) {

            if (entry.kind === 'file') {
                onFileFound(relativePath, await entry.getFile());
            }

            if (entry.kind === 'file') {
                //const file = await entry.getFile();
                //console.log(`Name: ${file.name}, Size: ${file.size}, Path: ${relativePath}${file.name}`);
            } else if (entry.kind === 'directory') {
                onFolderFound(relativePath, entry.name);
                await FolderParser.listFiles(entry, `${relativePath}${entry.name}/`, onFileFound, onFolderFound);
            }
        }
    }
}