
// This message is sent by the server to the client, with the 
// generated code assing to that client

import { IWSMessage } from "../dataSharing/wsMessage";

export const enum ConnectionPurpose {
  Unknown,
  Sender,
  Receiver
}

export const WSMessageTypeSendPurpose = "send-purpose";

export interface IWSMessageDataSendPurpose extends IWSMessage {
  purpose: ConnectionPurpose;
}

export class WSMessageSendPurpose implements IWSMessageDataSendPurpose {
  constructor(
    public purpose: ConnectionPurpose) {
  }

  public messageType = WSMessageTypeSendPurpose;
}