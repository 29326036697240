
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FileSharingSender } from './fileSharingSender';
import { FileSharingReceiver } from './fileSharingReceiver';

export function FileSharing() {

    // To grab the location
    const location = useLocation();

    // To store the share ID
    const [shareID, setShareID] = useState<string | undefined>();

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const urlShareID = searchParams.get('shareid');

        if (urlShareID) {
            setShareID(urlShareID);
        }
        else {
            setShareID('');
        }


    }, [location.search]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <div className="product-presentation">
                <h1 className="product-name">Copy Files</h1>
                <p className="product-feature">No uploads or downloads.</p>
                <p className="product-feature">No zip required.</p>
                <p className="product-feature">No limits.</p>
                <p className="product-feature">No ads.</p>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                }}>

                <div
                    style={{
                        display: 'flex',
                    }}>

                    {shareID !== undefined && shareID === '' &&
                        <FileSharingSender />
                    }

                    {shareID !== undefined && shareID !== '' &&
                        <FileSharingReceiver shareID={shareID} />
                    }
                </div>
            </div>

            <div
                style={{
                    flexGrow: 1
                }}>

            </div>
            <div className='footer'>
                <div className="footer-item">
                    <a href="http://www.linkedin.com/in/nuno20090" target="_blank" rel="noopener noreferrer">Nuno C.</a>
                </div>
                <div className="footer-item">
                    <a href="https://www.buymeacoffee.com/nuno20090" target="_blank" rel="noopener noreferrer">Buy me a coffee</a>
                </div>
                <div style={{ width: '5px' }}></div>
                <div className="footer-item">
                    <a href="https://webrtc.org/" target="_blank" rel="noopener noreferrer">WebRTC</a>
                </div>
                <div className="footer-item">
                    <a href="https://nodejs.org/" target="_blank" rel="noopener noreferrer">Node</a>
                </div>
                <div className="footer-item">
                    <a href="https://react.dev/" target="_blank" rel="noopener noreferrer">React</a>
                </div>
                <div className="footer-item">
                    <a href="https://www.typescriptlang.org/" target="_blank" rel="noopener noreferrer">TypeScript</a>
                </div>
                <div style={{ width: '5px' }}></div>
                <div className="footer-item-small">
                    v1.01
                </div>
            </div>
        </div>
    );
}