import { FileInfoLocal } from "../data/fileInfoLocal";
import { TextManip } from "../textManip/textManip";

export class FileContentsReader {

    static CHUNK_SIZE = (10 * 1024);

    public static getFileChunksSize(file: FileInfoLocal): number {
        return Math.ceil(file.size / FileContentsReader.CHUNK_SIZE);
    }

    public static async getFileContents(
        file: FileInfoLocal,
        filePartIndex: number
    ): Promise<string | undefined> {

        const startByte = filePartIndex * FileContentsReader.CHUNK_SIZE;
        const endByte = startByte + FileContentsReader.CHUNK_SIZE;

        if (file.fileHandle === undefined) {
            return undefined;
        }

        const fileDataSlice = file.fileHandle.slice(startByte, endByte);

        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (event) => {
                if (event.target && event.target.result) {

                    const arrayBuffer = event.target.result as ArrayBuffer;
                    const base64String = TextManip.binaryDataToBase64String(arrayBuffer);
                    resolve(base64String);

                } else {
                    reject(new Error("Unable to read the file."));
                }
            };

            fileReader.onerror = (error) => {
                reject(new Error(`File reading error: ${error}`));
            };

            fileReader.readAsArrayBuffer(fileDataSlice);
        });
    }
}
