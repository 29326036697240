import React, { useEffect, useRef, useState } from "react";
import { FileInfoLocal } from "../common/data/fileInfoLocal";
import { PayloadType } from "../common/socketIOMessages/dataSharing/payloadTypeMessage";
import { CardSelectFilesToSend } from "./cards/cardSelectFilesToSend";
import { CardItemsToSendDescription } from "./cards/cardItemsToSendDescription";
import { CardShareURL } from "./cards/cardShareURL";
import { CardProgress } from "./cards/cardProgress";
import { CardBuyCoffee } from "./cards/cardBuyCoffee";
import { CardMessage } from "./cards/cardMessage";
import { WSConnectionSender } from "../library/wsConnection/wsConnectionSender";

export function FileSharingSender() {

  // Server address form .env file
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const serverSocketAddress = process.env.REACT_APP_SERVER_SOCKET_ADDRESS;

  // Client address form .env file
  const clientAddress = process.env.REACT_APP_CLIENT_ADDRESS;

  // Access to Server
  const [serverAvailable, setServerAvailable] = useState<boolean | undefined>(undefined);

  // Payload type
  const [payloadType, setPayloadType] = useState<PayloadType>();

  // Selected items to be transfered
  const [selectedItems, setSelectedItems] = useState<FileInfoLocal[]>([]);

  // Web Sockets Connection 
  const wsConnection = useRef<WSConnectionSender>();

  // Client code generated by the server
  const [clientCode, setClientCode] = useState<string>("");

  const [transferStarted, setTransferStarted] = useState<boolean>(false);
  const [transferFinished, setTransferFinished] = useState<boolean>(false);

  // Cards visibility
  const [showCardSelectFiles, setShowCardSelectFiles] = useState<boolean>(false);
  const [showCardItemsDescription, setShowCardItemsDescription] = useState<boolean>(false);
  const [showCardShareLink, setShowCardShareLink] = useState<boolean>(false);
  const [showCardProgress, setShowCardProgress] = useState<boolean>(false);
  const [showCardBuyCoffee, setShowCardBuyCoffee] = useState<boolean>(false);
  const [showCardMessage, setShowCardMessage] = useState<boolean>(false);

  // Card Message data
  const [message, setMessage] = useState<string | undefined>();

  // Card Items picker data
  const [readingFilesToSend, setReadingFilesToSend] = useState<boolean>(false);

  // Card Progress data
  const [progressMessage, setProgressMessage] = useState<string>("");
  const [progressItems, setProgressItems] = useState<number>(-1);
  const [progressItem, setProgressItem] = useState<number>(-1);
  const [progressItemName, setProgressItemName] = useState<string>("");
  //const [transferComplete, setTransferComplete] = useState<boolean>(false);

  // Messages
  const clearMessage = () => {
    setMessage(undefined);
  }

  const setMessageServerConnectionError = (reason: string) => {
    setMessage("Something's wrong. Sorry...");
  }

  // Function to be called when the server returns a unique code for us
  const handleClientCodeGenerated = (clientCode: string) => {
    setClientCode(clientCode);
    clearMessage();
  }

  const handleReceiverStartedTransfer = () => {
    setTransferStarted(true);
  }

  // Function called when there's some progress done transferring an item
  const handleTransferProgressUpdate = (itemsProgress: number, itemProgress: number, itemName: string) => {

    setProgressMessage("Sending files...");
    setProgressItems(itemsProgress);
    setProgressItem(itemProgress);
    setProgressItemName(itemName);
  }

  // Function called when the transfer of all items is complete
  const handleTransferComplete = () => {

    setTransferFinished(true);
  }

  const handlePeerDisconnected = () => {

    if (transferFinished === false) {

      setMessage("Oh no... Receiver has lost connection...");
      setShowCardMessage(true);

      setShowCardProgress(false);
    }
  }


  // Get the destination URL for the receiver
  const getDestinationURL = () => {
    return `${clientAddress}?shareid=${clientCode}`;
  }

  const checkConnectionToServer = () => {

    // Calling a route in the server to check access to it...
    fetch(`${serverAddress}/hello`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(() => {
        setServerAvailable(true);
      })
      .catch(() => {
        setServerAvailable(false);
      });

    setMessage("Connecting to server...");
  }

  const establishConnection = () => {

    if (
      serverSocketAddress === undefined ||
      wsConnection.current === undefined
    ) {
      return;
    }

    setMessage("Connecting to server...");

    wsConnection.current.connectToServer(
      serverSocketAddress,
      handleClientCodeGenerated,
    )
  };

  useEffect(() => {

    if (transferStarted && transferFinished) {

      setShowCardProgress(true);
      setProgressMessage("Done!");

      setTimeout(() => {
        setShowCardBuyCoffee(true);
      }, 5000);
    }
    else if (transferStarted) {
      setShowCardProgress(true);
    }

  }, [transferStarted, transferFinished]);

  useEffect(() => {
    setShowCardMessage(message !== undefined);
  }, [message]);

  useEffect(() => {
    setShowCardItemsDescription(readingFilesToSend || selectedItems.length > 0);
  }, [readingFilesToSend, selectedItems]);

  useEffect(() => {

    if (selectedItems.length === 0) {
      return;
    }

    if (payloadType === undefined) {
      return;
    }

    if (wsConnection.current) {
      // Send the paylod description to the receiver
      wsConnection.current.sendPayloadDescriptions(payloadType, selectedItems);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    if (clientCode !== "") {
      setShowCardSelectFiles(true);
    }
  }, [clientCode]);

  // This should be called to update the share URL when a clientCode is given by the server
  //useEffect(() => {
  //  setShareURL(getDestinationURL());
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [clientCode]);

  useEffect(() => {

    if (serverAvailable === undefined) {
      return;
    }

    if (serverAvailable) {
      establishConnection();
    }
    else {
      setMessageServerConnectionError("Test Message was not successful");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverAvailable]);

  useEffect(() => {

    wsConnection.current = new WSConnectionSender();
    wsConnection.current.registerAsSender(
      handleReceiverStartedTransfer,
      handleTransferProgressUpdate,
      handleTransferComplete,
      handlePeerDisconnected,
    );

    checkConnectionToServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div style={{ display: "flex", flexDirection: "column" }}>

      <div className="card-stack">

        {showCardSelectFiles && (
          <CardSelectFilesToSend
            setPayloadType={setPayloadType}
            setSelectedItems={setSelectedItems}
            setReadingFilesToSend={setReadingFilesToSend}
            cardIndex={1}
          />
        )}

        {showCardItemsDescription && (
          <CardItemsToSendDescription
            readingFilesToSend={readingFilesToSend}
            selectedItems={selectedItems}
            callbackContinue={() => {
              setShowCardShareLink(true);

              if (wsConnection.current) {
                wsConnection.current.startSendFiles();
              }
            }}
            cardIndex={2}
          />
        )}

        {showCardShareLink && (
          <CardShareURL
            shareURL={getDestinationURL()}
            cardIndex={3}
          />
        )}

        {showCardProgress && (
          <CardProgress
            progressMessage={progressMessage}
            progressItems={progressItems}
            progressItem={progressItem}
            progressItemName={progressItemName}
            transferComplete={transferFinished}
            cardIndex={4}
          />
        )}

        {showCardBuyCoffee && (
          <CardBuyCoffee
            cardIndex={5} />
        )}

        {showCardMessage && message && (
          <CardMessage
            message={message}
          />
        )}

      </div>
    </div>

  );
}