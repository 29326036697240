
import { IWSMessage } from "./wsMessage";

export const WSMessageTypeFileTransferTail = "file-transfer-tail";

export interface IWSMessageDataFileTransferTail extends IWSMessage {
}

export class WSMessageDataFileTransferTail implements IWSMessageDataFileTransferTail {
    public messageType = WSMessageTypeFileTransferTail;
}

