import React from "react";
import { getCardStyle } from "./cardStyles";

interface CardShareURLProps {
    shareURL: string;
    cardIndex: number;
}

export function CardShareURL({
    shareURL,
    cardIndex
}: CardShareURLProps): JSX.Element {

    const copyButtonLabelDefault = "Copy to clipboard";

    const [copyButtonLabel, setCopyButtonLabel] = React.useState<string>(copyButtonLabelDefault);

    return (
        <div
            className={`card card-share-url ${getCardStyle(cardIndex)}`}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5em',
                alignItems: 'center'
            }}>

                <div
                    className="card-text-h1"
                >
                    Share this
                </div>

                <div
                    className="card-text-h3 share-link"
                >
                    {shareURL}
                </div>

                <button
                    className="button-main"
                    style={{ width: '15rem' }}
                    onClick={() => {
                        navigator.clipboard.writeText(shareURL);
                        setCopyButtonLabel("Copied!");
                        setTimeout(() => {
                            setCopyButtonLabel(copyButtonLabelDefault);
                        }, 2000);
                    }}
                >{copyButtonLabel}</button>

                <div className="card-text-h4">
                    <b>This link will only work once!</b>
                </div>
            </div>
        </div>
    )
}