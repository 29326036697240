
import { IWSMessage } from "./wsMessage";

export const WSMessageTypePayloadDescriptionEnd = "payload-description-end";

export interface IWSMessageDataPayloadDescriptionEnd extends IWSMessage {
}

export class WSMessageDataPayloadDescriptionEnd implements IWSMessageDataPayloadDescriptionEnd {

    public messageType = WSMessageTypePayloadDescriptionEnd;
}

