
import { Socket, io } from 'socket.io-client';
import { IWSMessageDataSendClientCode, WSMessageTypeSendClientCode } from '../../common/socketIOMessages/clientConnection/sendClientCode';
import { ConnectionPurpose, WSMessageSendPurpose } from '../../common/socketIOMessages/clientConnection/sendPurpose';
import { IWSMessage } from '../../common/socketIOMessages/dataSharing/wsMessage';

export class WSConnection {

  protected socket: Socket | undefined;

  private callBackSetClientCode: ((code: string) => void) | undefined;

  private connectionPurpose: ConnectionPurpose;

  constructor(connectionPurpose: ConnectionPurpose) {
    this.connectionPurpose = connectionPurpose;
  }

  public connectToServer = (
    url: string,
    setClientCode: (code: string) => void,
  ): void => {

    this.callBackSetClientCode = setClientCode;

    this.socket = io(url, { transports: ['websocket'] });

    //this.socket.on('connect', () => {
    //  console.log('Connected to the server');
    //});

    //this.socket.on('disconnect', () => {
    //  console.log('Disconnected from the server');
    //});

    this.socket.on(WSMessageTypeSendClientCode, (data: IWSMessageDataSendClientCode) => {
      this.handleSendClientCode(data);
    });

    this.registerSocketEvents(this.socket);
  }

  protected registerSocketEvents = (socket: Socket): void => {
    throw new Error("registerSocketEvents not implemented in the client");
  }

  private handleSendClientCode = (messageData: IWSMessageDataSendClientCode) => {

    // If there's a callback defined, call it with the new code
    if (this.callBackSetClientCode) {
      // console.log('Client code received: ' + messageData.clientCode);
      this.callBackSetClientCode(messageData.clientCode);
    }

    // After we get a code, send a message to indicate why are we connecting to the server
    this.sendClientPurpose();
  }

  private sendClientPurpose = () => {

    // Send the code to the client
    const msgData = new WSMessageSendPurpose(this.connectionPurpose);
    this.sendMessage(msgData);
  }

  public close = () => {
    if (!this.socket) {
      return;
    }
    this.socket.disconnect();
  }

  protected sendMessage = (message: IWSMessage) => {
    if (!this.socket) {
      return;
    }

    this.socket.emit(message.messageType, message);
  }
}