import React from "react";

import { FileInformation } from "../common/fileInformation";
import { FileInfoLocal } from "../../common/data/fileInfoLocal";
import { getCardStyle } from "./cardStyles";

interface CardItemsToSendDescriptionProps {
    readingFilesToSend: boolean;
    selectedItems: FileInfoLocal[];
    cardIndex: number;
    callbackContinue: () => void;
}

export function CardItemsToSendDescription({
    readingFilesToSend,
    selectedItems,
    cardIndex,
    callbackContinue
}: CardItemsToSendDescriptionProps): JSX.Element {

    return (
        <div
            className={`card card-items-to-send ${getCardStyle(cardIndex)}`}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {readingFilesToSend && (
                    <div
                        className="card-text-h1"
                    >
                        Reading files...
                    </div>
                )}

                {!readingFilesToSend && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <div
                            className="card-text-h1"
                        >
                            What you're about to send
                        </div>

                        <div className="mt-8">
                            <FileInformation items={selectedItems}></FileInformation>
                        </div>

                        <button
                            className="button-main mt-8"
                            onClick={() => { callbackContinue() }}
                        >
                            Continue
                        </button>
                    </div>


                )}
            </div>
        </div >

    )
}