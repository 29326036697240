
import { IWSMessage } from "./wsMessage";

export const WSMessageTypeReceiverStartedTransfer = "receiver-started-transfer";

export interface IWSMessageDataReceiverStartedTransfer extends IWSMessage {
}

export class WSMessageDataReceiverStartedTransfer implements IWSMessageDataReceiverStartedTransfer {
    public messageType = WSMessageTypeReceiverStartedTransfer;
}

