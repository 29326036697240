import React from "react";

import { PayloadType } from "../../common/socketIOMessages/dataSharing/payloadTypeMessage";
import { FileInfoLocal } from "../../common/data/fileInfoLocal";
import { ItemPicker } from "../common/itemPicker";
import { getCardStyle } from "./cardStyles";

interface CardSelectFilesToSendProps {
    setPayloadType: (payloadType: PayloadType) => void;
    setSelectedItems: (selectedItems: FileInfoLocal[]) => void;
    setReadingFilesToSend: (readingFilesToSend: boolean) => void;
    cardIndex: number;
}


export function CardSelectFilesToSend({
    setPayloadType,
    setSelectedItems,
    setReadingFilesToSend,
    cardIndex
}: CardSelectFilesToSendProps): JSX.Element {

    return (
        <div
            className={`card card-select-files-to-send ${getCardStyle(cardIndex)}`}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5em'
            }}>

                <div
                    className="card-text-h1"
                >
                    Select what you want to do
                </div>

                <ItemPicker
                    setPayloadType={setPayloadType}
                    setSelectedItems={setSelectedItems}
                    setReadingFiles={setReadingFilesToSend}
                />
            </div>
        </div>

    )
}