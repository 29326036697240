
import { IWSMessage } from "./wsMessage";

export const WSMessageTypeFileTransferDone = "file-transfer-done";

export interface IWSMessageDataFileTransferDone extends IWSMessage {
}

export class WSMessageDataFileTransferDone implements IWSMessageDataFileTransferDone {
    public messageType = WSMessageTypeFileTransferDone;
}

