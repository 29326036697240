

export async function createDirectories(
    dirHandle: FileSystemDirectoryHandle,
    path: string): Promise<FileSystemDirectoryHandle> {
    let currentDirHandle = dirHandle;
    const dirs = path.split('/');

    for (const dir of dirs) {
        if (dir === '') {
            continue;
        }
        currentDirHandle = await currentDirHandle.getDirectoryHandle(dir, { create: true });
    }

    return currentDirHandle;
}