import React from "react";

interface CardMessageProps {
    message: string;
}

export function CardMessage({
    message,
}: CardMessageProps): JSX.Element {
    return (
        <div
            className={`card card-message`}
        >
            <div className="card-text-h1">
                {message}
            </div>
        </div>
    )
}