
import { IWSMessage } from "./wsMessage";


export const WSMessageTypeShareNotAvailable = "share-not-available";

export interface IWSMessageDataShareNotAvailable extends IWSMessage {
}

export class WSMessageDataShareNotAvailable implements IWSMessageDataShareNotAvailable {
    public messageType = WSMessageTypeShareNotAvailable;
}

