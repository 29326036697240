
import React, { useEffect, useState } from "react";
import { PayloadType } from "../../common/socketIOMessages/dataSharing/payloadTypeMessage";

interface ItemReceiverProps {
    payloadType: PayloadType;
    callbackDoAction: (action: "downloadFile" | "downloadFolder" | "syncFolder") => void;
}

export function ItemReceiver({
    payloadType,
    callbackDoAction
}: ItemReceiverProps) {

    // Flag to indicate if folder selection is available (probably not available in mobile browsers)
    const [folderSelectionIsAvailable, setFolderSelectionIsAvailable] = useState<boolean | undefined>();

    const [showSaveFileButton, setShowSaveFileButton] = useState<boolean>(false);
    const [showSaveFilesButton, setShowSaveFilesButton] = useState<boolean>(false);
    const [showSaveFolderButton, setShowSaveFolderButton] = useState<boolean>(false);

    const handleSaveFile = () => {
        callbackDoAction("downloadFile");
    }

    const handleSaveFolder = () => {
        callbackDoAction("downloadFolder");
    }

    useEffect(() => {

        setShowSaveFileButton(payloadType === "file");
        setShowSaveFilesButton(payloadType === "multiple files" && folderSelectionIsAvailable === true);
        setShowSaveFolderButton(payloadType === "folder" && folderSelectionIsAvailable === true);
    }, [payloadType, folderSelectionIsAvailable]);

    useEffect(() => {
        setFolderSelectionIsAvailable(window.showDirectoryPicker !== undefined);
    }, []);

    return (
        <>
            {
                (payloadType === "file" || payloadType === "multiple files" || payloadType === "folder") &&
                <div>
                    {showSaveFileButton &&
                        <button
                            className="button-main"
                            onClick={() => { handleSaveFile() }}
                        >
                            Save File
                        </button>
                    }

                    {showSaveFilesButton &&
                        <button
                            className="button-main"
                            onClick={() => { handleSaveFolder() }}
                        >
                            Save files
                        </button>
                    }

                    {showSaveFolderButton &&
                        <>
                            <button
                                className="button-main"
                                onClick={() => { handleSaveFolder() }}
                            >
                                Save folder
                            </button>
                        </>
                    }

                    {!showSaveFileButton && !showSaveFilesButton && !showSaveFolderButton &&
                        <div className="card-text-h2">
                            Sorry, but this browser does not support saving folders. Ask the sender to zip the folder.
                        </div>
                    }
                </div>
            }
        </>
    );
}
