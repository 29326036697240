
import React, { useEffect, useRef, useState } from "react";
import { FileInfoLocal } from "../common/data/fileInfoLocal";
import { PayloadType } from "../common/socketIOMessages/dataSharing/payloadTypeMessage";
import { FileDownloadTrigger } from "./common/fileDownloadTrigger";
import { CardItemsToReceiveDescription } from "./cards/cardItemsToReceiveDescription";
import { CardProgress } from "./cards/cardProgress";
import { CardBuyCoffee } from "./cards/cardBuyCoffee";
import { CardMessage } from "./cards/cardMessage";
import { CardDiscoveringItems } from "./cards/cardDiscoveringItems";
import { WSConnectionReceiver } from "../library/wsConnection/wsConnectionReceiver";

interface FileSharingReceiverProps {
  shareID: string;
}

export function FileSharingReceiver({ shareID }: FileSharingReceiverProps) {

  // Server address form .env file
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const serverSocketAddress = process.env.REACT_APP_SERVER_SOCKET_ADDRESS;

  // Access to Server
  const [serverAvailable, setServerAvailable] = useState<boolean | undefined>(undefined);

  // Flag to indicate if the share ID is valid
  const [shareIDIsValid, setShareIDIsValid] = useState<boolean | undefined>(undefined);

  // Client code generated by the server
  const [clientCode, setClientCode] = useState<string>("");

  // Web Sockets Connection 
  const wsConnection = useRef<WSConnectionReceiver>();

  // Payload type
  const [payloadType, setPayloadType] = useState<PayloadType>();

  // Available Items to be transfered
  const [availableItems, setAvailableItems] = useState<FileInfoLocal[]>([]);

  // Cards visibility
  const [showCardDiscoveringItems, setShowCardDiscoveringItems] = useState<boolean>(false);
  const [showCardItemsDescription, setShowCardItemsDescription] = useState<boolean>(false);
  const [showCardMessage, setShowCardMessage] = useState<boolean>(false);
  const [showCardProgress, setShowCardProgress] = useState<boolean>(false);
  const [showCardDone, setShowCardDone] = useState<boolean>(false);

  // Card Message data
  const [message, setMessage] = useState<string | undefined>();

  // Card Progress data
  const [progressMessage, setProgressMessage] = useState<string>("");
  const [progressItems, setProgressItems] = useState<number>(-1);
  const [progressItem, setProgressItem] = useState<number>(-1);
  const [progressItemName, setProgressItemName] = useState<string>("");
  const [transferComplete, setTransferComplete] = useState<boolean>(false);

  const clearMessage = () => {
    setMessage(undefined);
  }

  const handlePayloadDiscoveryStart = () => {
    setMessage(undefined);
    setShowCardDiscoveringItems(true);
  }

  const handlePayloadDescription = (payloadType: PayloadType, availableItems: FileInfoLocal[]) => {
    setPayloadType(payloadType);
    setAvailableItems(availableItems);
  }

  const handleTransferProgressUpdate = (itemsProgress: number, itemProgress: number, itemName: string) => {

    setProgressMessage("Receiving files...");
    setProgressItems(itemsProgress);
    setProgressItem(itemProgress);
    setProgressItemName(itemName);
  }

  const handleTransferComplete = () => {

    setProgressMessage("Done!");
    setTransferComplete(true);

    setTimeout(() => {
      setShowCardDone(true);
    }, 3000);
  }

  const handleShareNotAvailableError = () => {
    setMessage("That link doesn't seem to be valid anymore. Try asking for a new one.");
    setShowCardMessage(true);
  }

  const handlePeerDisconnected = () => {
    setMessage("Oh no... Sender has lost connection...");
    setShowCardMessage(true);
    setShowCardProgress(false);
  }

  const setMessageServerConnectionError = (reason: string) => {
    setMessage("Something's wrong. Sorry...");
  }

  const handleClientCodeGenerated = (clientCode: string) => {
    setClientCode(clientCode);
  }

  // Establish connection to the server and the specified peer
  const establishConnection = async () => {

    setMessage("Connecting to server...");

    if (
      serverSocketAddress === undefined ||
      wsConnection.current === undefined
    ) {
      return;
    }

    wsConnection.current.connectToServer(
      serverSocketAddress,
      handleClientCodeGenerated,
    )
  }

  const downloadFile = (fileID: number) => {

    // Find the availble item with the specified ID
    const availableItem = availableItems.find((item) => item.id === fileID);

    if (availableItem === undefined) {
      return;
    }

    if (wsConnection.current === undefined) {
      return;
    }

    setShowCardProgress(true);

    // Download the file using the fileTransfer object
    wsConnection.current.downloadFile(
      fileID,
      (fileContents, fileName) => {
        FileDownloadTrigger(fileContents, fileName);
      }
    );
  }

  const streamFile = async (fileID: number) => {

    // Find the availble item with the specified ID
    const availableItem = availableItems.find((item) => item.id === fileID);

    if (availableItem === undefined) {
      return;
    }

    // Request a destination from the user

    const filePickerOptions = {
      suggestedName: availableItem.name,
      startIn: 'downloads'
    }

    // Ask the user for a file in the disk to where the file should be saved
    window.showSaveFilePicker(filePickerOptions).then(async (handle) => {

      if (wsConnection.current === undefined) {
        return;
      }

      // Create a writable stream to the destination file
      const destinationFileHandle = await handle.createWritable();

      // Show the progress card
      setProgressMessage("Waiting for permissions...")
      setShowCardProgress(true);

      // Download the file using the fileTransfer object
      wsConnection.current.streamFile(
        fileID,
        destinationFileHandle
      );

    }).catch((error) => {
      return;
    });
  }

  const downloadFolder = () => {

    // Request a destination from the user
    window.showDirectoryPicker().then(async (directoryHandle) => {

      if (wsConnection.current === undefined) {
        return;
      }

      wsConnection.current.ensureWritePermissions(directoryHandle).then(() => {

        if (wsConnection.current === undefined) {
          return;
        }

        // Show the progress card
        setProgressMessage("Waiting for permissions...")
        setShowCardProgress(true);

        // Download all the files
        wsConnection.current.downloadFolder(directoryHandle);
      });

    }).catch((error) => {
      return;
    });
  }

  const syncFolder = () => {
  }

  const handleDoReceiveAction = (action: "downloadFile" | "downloadFolder" | "syncFolder"): void => {

    switch (action) {
      case "downloadFile":
        {
          // Grab the first item of the available items
          if (availableItems.length === 0) {
            return;
          }

          const availableFile = availableItems[0];
          if (availableFile.type !== "file") {
            return;
          }

          if (wsConnection.current === undefined) {
            return;
          }

          // Get the threshold
          const FileSizeStreamThreshold = wsConnection.current.getFileSizeStreamSizeThreshold();

          // If the file is bigger than the threshold, stream it
          if (availableFile.size > FileSizeStreamThreshold) {
            streamFile(availableFile.id);
          }
          else {
            downloadFile(availableFile.id);
          }
        }
        break;

      case "downloadFolder":
        downloadFolder();
        break;

      case "syncFolder":
        syncFolder();
        break;
    }
  }

  const checkConnectionToServer = () => {

    setMessage("Checking connection to server...");

    fetch(`${serverAddress}/hello`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(() => {
        setServerAvailable(true);
      })
      .catch(() => {
        setServerAvailable(false);
      });
  };

  const checkShareIDValidity = () => {

    fetch(`${serverAddress}/checkShareID?shareID=${shareID}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((response) => {

        const isShareIDValid = response.toLowerCase() === 'true';
        setShareIDIsValid(isShareIDValid);
      })
      .catch(() => {
        setShareIDIsValid(false);
      });
  }

  useEffect(() => {

    if (shareIDIsValid === undefined) {
      return;
    }

    if (shareIDIsValid) {
      establishConnection();
    }
    else {
      setMessage("That link doesn't seem to be valid anymore. Try asking for a new one.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareIDIsValid]);

  useEffect(() => {
    setShowCardMessage(message !== undefined);
  }, [message]);

  useEffect(() => {

    if (clientCode === "") {
      return;
    }

    if (wsConnection.current) {
      wsConnection.current.registerAsReceiver(
        shareID,
        handlePayloadDiscoveryStart,
        handlePayloadDescription,
        handleTransferProgressUpdate,
        handleTransferComplete,
        handleShareNotAvailableError,
        handlePeerDisconnected,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCode]);

  useEffect(() => {

    if (availableItems.length === 0) {
      return;
    }

    clearMessage();

    setShowCardItemsDescription(true);
  }, [availableItems]);

  useEffect(() => {

    if (serverAvailable === undefined) {
      return;
    }

    if (serverAvailable) {
      checkShareIDValidity();
    }
    else {
      setMessageServerConnectionError("Test Message was not successful");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverAvailable]);

  useEffect(() => {

    // Create a new object to connect to the server
    wsConnection.current = new WSConnectionReceiver();

    checkConnectionToServer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (

    <div style={{ display: "flex", flexDirection: "column" }}>

      <div className="card-stack">

        {
          showCardDiscoveringItems && (
            <CardDiscoveringItems
              cardIndex={1}
            />
          )
        }

        {showCardItemsDescription && (
          <CardItemsToReceiveDescription
            payloadType={payloadType}
            availableItems={availableItems}
            handleDoReceiveAction={handleDoReceiveAction}
            cardIndex={2}
          />
        )}

        {showCardProgress && (
          <CardProgress
            progressMessage={progressMessage}
            progressItems={progressItems}
            progressItem={progressItem}
            progressItemName={progressItemName}
            transferComplete={transferComplete}
            cardIndex={3}
          />
        )}

        {showCardDone && (
          <CardBuyCoffee
            cardIndex={4}
          />
        )}

        {showCardMessage && message && (
          <CardMessage
            message={message}
          />
        )}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
      </div>
    </div>
  );
}