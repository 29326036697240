
import { IWSMessage } from "./wsMessage";

export const WSMessageTypeFileTransferPart = "file-transfer-part";

export interface IWSMessageDataFileTransferPart extends IWSMessage {
    chunkTotal: number;
    chunkIndex: number;
    data: string;
}

export class WSMessageDataFileTransferPart implements IWSMessageDataFileTransferPart {

    constructor(
        public chunkTotal: number,
        public chunkIndex: number,
        public data: string
    ) { }

    public messageType = WSMessageTypeFileTransferPart;
}

