
import {
    ConnectionPurpose
} from "../../common/socketIOMessages/clientConnection/sendPurpose";

import {
    FileInfoLocal
} from "../../common/data/fileInfoLocal";

import {
    WSConnection
} from "./wsConnection";

import {
    FileContentsReader
} from "../../common/3way/fileContentsReader";

import {
    PayloadType,
    WSMessageDataPayloadType
} from "../../common/socketIOMessages/dataSharing/payloadTypeMessage";

import {
    IPayloadItemDescription,
    WSMessageDataPayloadDescription
} from "../../common/socketIOMessages/dataSharing/payloadDescriptionMessage";

import {
    WSMessageDataPayloadDescriptionEnd
} from "../../common/socketIOMessages/dataSharing/payloadDescriptionEndMessage";

import {
    WSMessageDataFileTransferHead
} from "../../common/socketIOMessages/dataSharing/fileTransferHead";

import {
    WSMessageDataFileTransferTail
} from "../../common/socketIOMessages/dataSharing/fileTransferTail";

import {
    WSMessageDataFileTransferPart
} from "../../common/socketIOMessages/dataSharing/fileTransferPart";

import {
    WSMessageDataStartTransferEnd
} from "../../common/socketIOMessages/dataSharing/startTransferEndMessage";

import {
    WSMessageTypeReceiverStartedTransfer
} from "../../common/socketIOMessages/dataSharing/receiverStartedTransfer";

import {
    WSMessageDataFileTransferDone
} from "../../common/socketIOMessages/dataSharing/fileTransferDone";

import {
    IWSMessageDataBatchReceived,
    WSMessageTypeBatchReceived
} from "../../common/socketIOMessages/dataSharing/batchMessage";

import { BatchWork } from "../../common/batches/batch";
import { Socket } from "socket.io-client";
import { Progress } from "../progress";
import { WSMessageTypePeerDisconected } from "../../common/socketIOMessages/dataSharing/peerDisconnected";

export class WSConnectionSender extends WSConnection {

    constructor() {
        super(ConnectionPurpose.Sender);
    }

    public registerAsSender = (
        callBackReceiverStartedTransfer: () => void,
        callBackReportItemsProgress: (itemsProgress: number, itemProgress: number, itemName: string) => void,
        callBackOnTransferDone: () => void,
        callbackPeerDisconnected: () => void,
    ) => {
        this.callBackReceiverStartedTransfer = callBackReceiverStartedTransfer;
        this.callBackReportItemsProgress = callBackReportItemsProgress;
        this.callBackOnTransferDone = callBackOnTransferDone;
        this.callbackPeerDisconnected = callbackPeerDisconnected;
    }

    protected registerSocketEvents = (socket: Socket) => {

        socket.on(WSMessageTypeBatchReceived, (data: IWSMessageDataBatchReceived) => {
            this.handleBatchReceived(data);
        });

        socket.on(WSMessageTypeReceiverStartedTransfer, (data: any) => {
            this.handleReceiverStartedTransfer();
        });

        socket.on(WSMessageTypePeerDisconected, (data: any) => {
            this.handlePeerDisconnected();

        });
    }

    private handleBatchReceived = (data: IWSMessageDataBatchReceived) => {

        // Find the batch in the active batches
        const batch = this.activeBatch;
        if (batch === undefined) {
            return;
        }

        batch.confirm(data.batchPart);
    }

    private handleReceiverStartedTransfer = () => {

        // Call the call back to indicate the the receiver has started the transfer
        // We should probably started the progress

        if (this.callBackReceiverStartedTransfer) {
            this.callBackReceiverStartedTransfer();
        }
    }

    private handlePeerDisconnected = () => {
        if (this.callbackPeerDisconnected !== undefined) {
            this.callbackPeerDisconnected();
        }
    }

    public sendPayloadDescriptions(payloadType: PayloadType, itemsToCopy: FileInfoLocal[]) {

        if (this.socket === undefined) {
            return undefined;
        }

        // Save the items to copy
        this.itemsToCopy = itemsToCopy;

        // Send a message to indicate the type of payload

        const payloadTypeMessageData = new WSMessageDataPayloadType(payloadType);
        this.sendMessage(payloadTypeMessageData);

        // Send the data in batches

        this.sendBatch(
            "items description",
            "description",
            itemsToCopy,
            async (data, batchPart) => {

                const maxItemsToSend = 50;
                const itemsToCopy = data as FileInfoLocal[];

                const firstItem = batchPart * maxItemsToSend;
                const numItemsToSend = Math.min(maxItemsToSend, itemsToCopy.length - firstItem);
                const lastItem = firstItem + numItemsToSend;
                const finished = (lastItem === itemsToCopy.length);

                //console.log("Sending items", firstItem, "to", (lastItem - 1));

                let arrItemsDescriptions: Array<IPayloadItemDescription> = [];

                for (let curItem = (maxItemsToSend * batchPart); curItem < lastItem; curItem++) {

                    // Add the item to the list of items to be described
                    arrItemsDescriptions.push({
                        id: itemsToCopy[curItem].id,
                        name: itemsToCopy[curItem].name,
                        path: itemsToCopy[curItem].path,
                        size: itemsToCopy[curItem].size,
                        type: itemsToCopy[curItem].type
                    });
                }

                // Send this message
                let payloadDescriptionMessage = new WSMessageDataPayloadDescription(arrItemsDescriptions);
                this.sendMessage(payloadDescriptionMessage);

                // Check if we're done with message
                return finished;
            },
            () => {

                // Send this message
                let payloadDescriptionEndMessage = new WSMessageDataPayloadDescriptionEnd();
                this.sendMessage(payloadDescriptionEndMessage);

                // Start sending files to the server
                //this.startSendFiles();
            },
            () => {

                return true;
            }
        );
    }

    public async startSendFiles() {

        this.sendBatch(
            "items data",
            "data",
            this.itemsToCopy,
            async (data, batchPart) => {
                await this.sendChunk();
                return this.allDataSent;
            },
            () => {
                // Send the message to indicate we're done with the transfer
                this.sendMessage(new WSMessageDataFileTransferDone());

                if (this.callBackOnTransferDone) {
                    this.callBackOnTransferDone();
                }
            },
            () => {
                return true;
            }
        );

        /*
        const initialBatchesCount = 2;

        for (let i = 0; i < initialBatchesCount; i++) {
            await this.sendChunk();
        }
        */
    }

    public async proceed() {
        await this.sendChunk();
    }

    public async sendChunk(): Promise<void> {

        if (this.sendingData === true) {
            return;
        }

        this.sendingData = true;

        if (this.allDataSent === true) {
            console.log("sendChunk : All data sent. Leaving earlier...");
            this.sendingData = false;
            return;
        }

        const numberOfPartsToSend = BatchWork.NumberOfMessagesPerBatch;

        for (let i = 0; i < numberOfPartsToSend; i++) {
            if (await this.sendPart() === false) {
                this.allDataSent = true;
                break;
            }
        }

        this.sendingData = false;
    }

    public async sendPart(): Promise<boolean> {

        if (this.currentFileIndex === -1) {
            // We need to pick the first file
            this.currentFileIndex = 0;
        }

        if (this.currentFilePartIndex === -1) {
            // We need to pick the first file part
            this.currentFilePartIndex = 0;
        }

        // Get the handle to the file
        const file = this.itemsToCopy[this.currentFileIndex];

        if (file === undefined) {
            return false;
        }

        if (this.currentFilePartIndex === 0) {

            // Send the message that indicates that we are starting the transfer of a new file
            this.sendMessage(new WSMessageDataFileTransferHead(file.id));
        }

        const localFilePartIndex = this.currentFilePartIndex;

        // Check how much packets we need to send for this file
        const filePartsCount = FileContentsReader.getFileChunksSize(file);

        if (filePartsCount === 0) {

            // We're done with this file
            this.sendMessage(new WSMessageDataFileTransferTail());

            // Update progress
            this.reportProgress();

            // Increment the file index
            this.currentFileIndex++;

            // Reset the file part index
            this.currentFilePartIndex = -1;

            return true;
        }

        if (localFilePartIndex === -1) {
            debugger;
        }

        // Get the contents from the file
        const fileContents = await FileContentsReader.getFileContents(
            file,
            localFilePartIndex
        );

        if (fileContents === undefined) {
            // There was an error reading the file
            return true;
        }

        // Send the file contents to the receiver
        this.sendMessage(new WSMessageDataFileTransferPart(
            filePartsCount,
            localFilePartIndex,
            fileContents)
        );

        // Update progress
        this.reportProgress();

        // Increment the file part index
        this.currentFilePartIndex++;

        if (filePartsCount === this.currentFilePartIndex) {

            // We're done with this file
            this.sendMessage(new WSMessageDataFileTransferTail());

            // Update progress
            this.reportProgress();

            // Increment the file index
            this.currentFileIndex++;

            // Reset the file part index
            this.currentFilePartIndex = -1;
        }

        if (this.currentFileIndex === this.itemsToCopy.length) {

            this.sendMessage(new WSMessageDataStartTransferEnd());

            // Update progress
            this.reportProgress();

            // We're done with all the files
            return false;
        }

        return true;
    }

    public sendBatch = (
        batchName: string,
        batchType: "data" | "description",
        data: any,
        performSendBatch: (data: any, batchPart: number) => Promise<boolean>,
        onBatchCompleted: () => void,
        somethingToDo: () => boolean
    ) => {

        const newBatch = new BatchWork(
            batchName,
            batchType,
            data,
            performSendBatch,
            () => {

                // Remove the batch from the active batches
                //const index = this.activeBatches.indexOf(newBatch);
                //if (index > -1) {
                //    this.activeBatches.splice(index, 1);
                //}

                this.activeBatch = undefined;

                // Call the callback
                onBatchCompleted();
            },
            this.sendMessage,
            somethingToDo
        );

        this.activeBatch = newBatch;

        newBatch.start();
    }

    protected reportProgress = () => {

        if (this.callBackReportItemsProgress === undefined) {
            return;
        }

        let itemName = "";
        let itemProgress = 0;
        let itemsProgress = 0;

        if (this.allDataSent === true || this.currentFileIndex >= this.itemsToCopy.length) {

            itemsProgress = 100;
        }
        else if (this.currentFileIndex !== -1) {

            // Get the file
            const file = this.itemsToCopy[this.currentFileIndex];

            // Get the file parts count
            const filePartsCount = FileContentsReader.getFileChunksSize(file);

            // Calculate the progress
            if (this.currentFilePartIndex !== -1) {
                itemProgress = Progress.calcProgress(filePartsCount, this.currentFilePartIndex);
            }

            itemsProgress = Progress.calcProgress(this.itemsToCopy.length, this.currentFileIndex);
            itemName = file.name;
        }

        this.callBackReportItemsProgress(itemsProgress, itemProgress, itemName);
    }

    protected itemsToCopy: FileInfoLocal[] = [];
    protected activeBatch: BatchWork | undefined = undefined;

    public currentFileIndex = -1;
    public currentFilePartIndex = -1;
    public allDataSent = false;

    private sendingData = false;

    private callBackReceiverStartedTransfer: (() => void) | undefined = undefined;
    private callBackReportItemsProgress: ((itemsProgress: number, itemProgress: number, itemName: string) => void) | undefined = undefined;
    private callBackOnTransferDone: (() => void) | undefined = undefined;
    private callbackPeerDisconnected: (() => void) | undefined = undefined;

}