
import { IWSMessage } from "./wsMessage";


export const WSMessageTypeBatchStart = "batch-start";
export const WSMessageTypeBatchEnd = "batch-end";
export const WSMessageTypeBatchReceived = "batch-received";

//---------------------------------------------------------------------------------

export interface IWSMessageDataBatchStart extends IWSMessage {
    batchName: string;
    batchPart: number;
    batchType: "description" | "data";
}

export class WSMessageDataBatchStart implements IWSMessageDataBatchStart {

    constructor(
        public batchName: string,
        public batchPart: number,
        public batchType: "description" | "data") { };

    public messageType = WSMessageTypeBatchStart;
}

//---------------------------------------------------------------------------------

export interface IWSMessageDataBatchEnd extends IWSMessage {
    batchName: string;
    batchPart: number;
    batchType: "description" | "data";
}

export class WSMessageDataBatchEnd implements IWSMessageDataBatchEnd {

    constructor(
        public batchName: string,
        public batchPart: number,
        public batchType: "description" | "data") { };

    public messageType = WSMessageTypeBatchEnd;
}


//---------------------------------------------------------------------------------

export interface IWSMessageDataBatchReceived extends IWSMessage {
    batchName: string;
    batchPart: number;
}

export class WSMessageDataBatchReceived implements IWSMessageDataBatchReceived {

    constructor(public batchName: string, public batchPart: number) { };

    public messageType = WSMessageTypeBatchReceived;
}
