import React from "react";
import { ProgressBar } from "../common/progress";
import { getCardStyle } from "./cardStyles";

interface CardProgressSenderProps {
    progressMessage: string;
    progressItems: number;
    progressItem: number;
    progressItemName: string;
    transferComplete: boolean;
    cardIndex: number;
}

export function CardProgress({
    progressMessage,
    progressItems,
    progressItem,
    progressItemName,
    transferComplete,
    cardIndex
}: CardProgressSenderProps) {
    return (
        <div
            className={`card card-progress ${getCardStyle(cardIndex)}`}
        >
            <div className="card-text-h1">
                {progressMessage}
            </div>

            {
                progressItems !== -1 &&
                progressItem !== -1 &&
                progressItemName !== "" &&
                transferComplete === false && (
                    <div style={{
                        width: "90%",
                    }}
                        className="mt-4">
                        <ProgressBar value={progressItems} text={`${progressItems}%`} />
                        <ProgressBar value={progressItem} text={progressItemName} />
                    </div>
                )
            }
        </div>
    )
}