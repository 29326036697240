
import { IWSMessage } from "./wsMessage";

export const WSMessageTypeFileTransferHead = "file-transfer-head";

export interface IWSMessageDataFileTransferHead extends IWSMessage {
    fileID: number;
}

export class WSMessageDataFileTransferHead implements IWSMessageDataFileTransferHead {

    constructor(public fileID: number) { };

    public messageType = WSMessageTypeFileTransferHead;
}

