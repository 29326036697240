import React from 'react';

interface ProgressBarProps {
    text: string;
    value: number;
}

export function ProgressBar({ text, value }: ProgressBarProps) {

    const limitedValue = Math.min(100, Math.max(0, value));

    const backgroundStyle = {
        background: `linear-gradient(90deg, #9D7BAD ${limitedValue}%, transparent ${limitedValue}%, transparent 100%)`
    };

    return (
        <div style={{
            width: '100%',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
            border: '1px solid #9D7BAD',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'black',
            ...backgroundStyle,
        }}>
            {text}
        </div>
    );
};

